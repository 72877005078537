<template>
    <!-- 解决方案 -->
    <div class="zz_programme2">
      <div
        class="current-nav centerText"
        v-if="Object.keys(cpData).length != 0"
        style="height: 565px"
        :style="{
          background: `url(${cpData.jjfaImg}) 100%/100% no-repeat`
        }"
      ></div>
      <ul class="zz_programme2_type">
        <a
          :href="'#/zz_programme2?currentIdx=' + head_active"
          v-for="(item, idx) in sjHead"
          :key="idx"
          :class="[head_active == idx ? 'xz_type' : '']"
          @click="switchList(item, idx)"
        >
          {{ item.name }}
        </a>
      </ul>
      <div class="zz_programme2_nav" v-if="!currentId">
        <ul class="zz_programme2_list" v-if="sjList.length != 0">
          <li v-for="(item, idx) in sjList" :key="idx" @click="goDetails(item)">
            <a
              :href="
                '#/zz_programme2?currentIdx=' +
                  head_active +
                  '&currentId=' +
                  item.id
              "
            >
              <div class="list_image">
                <img
                  :src="item.site_images"
                  alt=""
                  style="width:100%;height: 100%;"
                />
                <div class="all_mark">
                  <span>——</span>
                <div class="mark_div two_ellipsis">{{ item.title }}</div>
                <span>——</span>
                </div>
              </div>
              <div class="list_title ellipsis">{{ item.title }}</div>
            </a>
          </li>
          <onLoading :show="loading"></onLoading>
        </ul>
        <div class="details_empty" v-else>
          <img
            src="@/assets/image/xwdt/xwdt-data.webp"
            alt=""
            style="margin:0 auto"
          />
        </div>
        <div class="all-pagination">
          <el-pagination
            prev-text="上一页"
            next-text="下一页"
            @current-change="handleCurrentChange"
            :current-page.sync="form.page"
            background
            :page-size="6"
            layout="prev, pager, next,total"
            :total="total"
            v-if="total > 6"
          >
          </el-pagination>
          <span class="no_meet_conditions" v-else>暂无更多数据</span>
        </div>
      </div>
      <div class="zz_programme2_nav2" v-else>
        <div class="nav2_html" v-html="sjData.maincontent"></div>
      </div>
    </div>
  </template>
  
  <script>
  import { getCaseTypes, getZzCaseList, getCaseDetail ,getPageMsg } from '@/api/api'
  import onLoading from '@/common/Loading/onLoading.vue'
  export default {
    components: { onLoading },
    data () {
      return {
        sjHead: [],
        sjList: [],
        head_active: 0,
        form: {
          page: 1,
          pagesize: 6,
          type_id: '',
          project: ''
        },
        total: 0,
        loading: false,
        currentId: 0,
        sjData: {},
        cpData:{}
      }
    },
    watch: {
      $route: {
        // 监听query参数变化
        handler () {
          if (this.$route.query.currentIdx) {
            this.head_active = this.$route.query.currentIdx
          } else {
            this.head_active = 0
            this.form.type_id = ''
            this.getSjdqList(this.form)
          }
          if (this.$route.query.currentId) {
            this.currentId = this.$route.query.currentId
            this.goDetails({ id: this.currentId })
          } else {
            this.currentId = 0
          }
        },
        deep: true
      }
    },
    methods: {
      getData () {
        let that = this
        if (this.$route.query.currentIdx) {
          this.head_active = this.$route.query.currentIdx
        } else {
          this.head_active = 0
        }
        if (this.$route.query.currentId) {
          this.currentId = this.$route.query.currentId
          this.goDetails({ id: this.currentId })
        } else {
          this.currentId = 0
        }
        let id = sessionStorage.getItem(this.changeData() + 'id')
        getPageMsg(id).then(res => {
          if (res.data.code == 1) {
            that.cpData = res.data.data.jjfa
          }
        })
        that.form.project = sessionStorage.getItem(this.changeData() + 'project')
        getCaseTypes(that.form.project).then(res => {
          if (res.data.code == 1) {
            res.data.data.unshift({
              name: '全部',
              id: ''
            })
            that.sjHead = res.data.data
          }
        })
        that.getSjdqList(that.form)
      },
      getSjdqList (e) {
        this.loading = true
        getZzCaseList(e).then(res => {
          if (res.data.code == 1) {
            this.sjList = res.data.data.list
            this.total = res.data.data.total
            this.loading = false
          }
        })
      },
      handleCurrentChange (val) {
        this.form.page = val
        this.getSjdqList(this.form)
      },
      switchList (e, i) {
        this.head_active = i
        this.currentId = 0
        this.form.type_id = e.id
        this.getSjdqList(this.form)
      },
      goDetails (e) {
        this.currentId = e.id
        this.sjData = {}
        getCaseDetail(e.id, this.form.project).then(res => {
          if (res.data.code == 1) {
            this.sjData = res.data.data
          }
        })
      }
    },
    mounted () {
      this.getData()
    }
  }
  </script>
  <style lang="less" scoped>
  @import url('~@/assets/css/mixins.less'); //混合
  .zz_programme2 {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: #f1f1f1;
    .zz_programme2_type {
      width: 15rem;
      background-color: #fff;
      margin: 3% auto;
      border-bottom: 1px solid #d4237a;
      text-align: center;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        vertical-align: middle;
        font-size: 0.18rem;
        color: #333333;
        position: relative;
        text-align: center;
        width: 2rem;
        padding: 0.3rem 0.15rem;
        cursor: pointer;
      }
      a:hover,
      .xz_type {
        background-color: #d4237a;
        color: #fff;
      }
    }
    .zz_programme2_list,
    .details_empty {
      width: 15.2rem;
      display: flex;
      flex-wrap: wrap;
      margin: 0 auto;
      position: relative;
      li {
        padding: 0.05rem 0.1rem;
        display: inline-block;
        font-size: 0.18rem;
        margin-top: 0.2rem;
        vertical-align: middle;
        width: calc(33.33333333% - 0.2rem);
        cursor: pointer;
        .list_image {
          width: 100%;
          height: 280px;
          position: relative;
        }
        .list_title {
          padding: 0.2rem;
          background-color: #fff;
        }
      }
      li:hover {
        color: #d4237a;
        .all_mark {
          height: 100%;
        }
      }
    }
    .details_empty {
      width: 15rem;
      background-color: #fff;
    }
    .zz_programme2_nav2 {
      width: 15.2rem;
      margin: 50px auto;
      .nav2_html {
        line-height: 1.5;
        /deep/img {
          margin: 0 auto !important;
          display: block;
        }
      }
    }
    .all-pagination {
      padding-bottom: 35px;
    }
  }
  </style>
  